<template>
  <v-card elevation="1" v-if="!isLoggedIn">
    <v-card-text class="px-4">
      <v-row>
        <v-col cols="12" class="d-flex justify-end mb-0 pb-0">
          <v-btn icon @click="$store.commit('account/toggleDarkTheme')">
            <v-icon>mdi-theme-light-dark</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex justify-center mt-0 pt-0 mb-6">
          <h3 class="text-uppercase">Iniciar sesión</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="my-0 py-0">
          <v-text-field
            prepend-icon="mdi-account"
            label="Usuario"
            type="text"
            ref="user"
            v-model="user"
            :rules="[() => !!user || 'Este campo es requerido']"
            :disabled="isLoading"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="my-0 py-0">
          <v-text-field
            prepend-icon="mdi-lock"
            label="Contraseña"
            :append-icon="passwordIcon"
            @click:append="switchPasswordField"
            :type="passwordType"
            ref="password"
            v-model="password"
            :rules="[() => !!password || 'Este campo es requerido']"
            :disabled="isLoading"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="mt-0 pt-0">
          <v-btn
            class="ma-2"
            :loading="isLoading"
            :disabled="isLoading"
            color="primary"
            block
            @click="logIn()"
          >
            Iniciar sesion
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'

export default {
  data() {
    return {
      user: '',
      password: '',
      formHasErrors: false,
      passwordType: 'password',
      passwordIcon: 'mdi-eye',
      loader: null,
      buttonLoading: false
    }
  },
  methods: {
    switchPasswordField() {
      if (this.passwordType === 'text') {
        this.passwordType = 'password'
        this.passwordIcon = 'mdi-eye'
      } else {
        this.passwordType = 'text'
        this.passwordIcon = 'mdi-eye-off'
      }
    },
    resetForm() {
      this.errorMessages = []
      this.formHasErrors = false

      Object.keys(this.form).forEach((f) => {
        this.$refs[f].reset()
      })
    },
    logIn() {
      let petition = true
      this.buttonLoading = true

      this.formHasErrors = false

      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) this.formHasErrors = true
        this.$refs[f].validate(true)
      })

      if (!this.formHasErrors && petition) {
        let data = {
          Username: this.user
        }

        let headers = {
          'Content-Type': 'application/json',
          Authorization: 'Basic ' + btoa(this.user + ':' + this.password)
        }

        axios
          .post(this.$loginBaseUrl + 'Session/Login', data, {
            headers: headers
          })
          .then((response) => {
            console.log(response)
            this.$store.commit('account/logIn', response.data)
            if (this.isLoggedIn && this.userData != null) {
              this.$router.push({ path: '/' }, () => {})
            }
          })
          .catch((error) => {
            console.log('Error: ', error)
          })
          .then(() => {
            this.buttonLoading = false
          })
      }
    }
  },
  computed: {
    ...mapState('account', ['darkTheme', 'isLoggedIn', 'userData']),
    form() {
      return {
        user: this.user,
        password: this.password
      }
    },
    isLoading() {
      return this.buttonLoading
    }
  },
  beforeCreate() {
    if (this.$store.state.account.isLoggedIn) {
      this.$router.push({ name: 'main' })
    }
  },
  mounted() {
    console.log(this.$endpointsBaseUrl)
  }
}
</script>
